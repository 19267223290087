<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-sw">
        <a href="https://www.summerwood.com/" target="_blank" alt="Summerwood">
          <img src="@/assets/casestudy/sw-logo.svg" alt="Summerwood">
        </a>
        <span class="sep"></span>
        <p>We design buildings that will last a lifetime.
          We are a group of inspired, passionate individuals, who for 25 years have
          been pursuing one goal - to make people feel special. We are a diverse bunch, but at our core, we share the same desire - To create exquisitely crafted garden sheds, gazebos,
          cabanas, and cabins that will provide a lifetime of memories and enjoyment. </p>
      </div>
    </div>

    <div class="laptop-img sw-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/sw-main-img.png" alt="Summerwood">
      </div>
    </div>

    <div class="container-about sw-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/sw-diamond.svg" alt="Summerwood">
        <h3>About the project</h3>
        <p>This website is one of our greatest achievement as a company, anything that you might dream of from a front-end perspective, you will most likely find in
          this 100+ page website.<br/>
          Home Décor Corp, the parent company of the SummerWood brand has been responsible of building, designing and delivering quality wood constructions for 25 years in Canada and in the
          United States.<br/>
          This website is a statement of their greatness and their incredible history of thousands of successful projects and customers.</p>

      </div>
    </div>
    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img class="doublefont" src="@/assets/casestudy/gibson-font.svg" alt="Sw main Font">
            <img src="@/assets/casestudy/claredon-font.svg" alt="SW secondary Font">
            <h3><span style="color:#789c48">Co</span><span style="color:#4b3d2b">lo</span><span style="color:#89725b">rs</span></h3>

            <div class="colors">
              <div class="fcolor sw-p">Primary</div>
              <div class="scolor sw-s">Secondary</div>
              <div class="tcolor sw-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>
            <div class="color-codes">
              <div class="ccodes">
                <p class="primary-sw">#fd6114</p>
                <p class="secondary-sw">#242782</p>
                <p class="third-sw">#b02d2d</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <testimonial backgroundTsColor="#4b3d2b" tsTitle="#789c48">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Peter Harvey, President and CEO</p>
        <p class="testimonial-text__content">"Any customer would be very happy with the services provided by the Scriptics team. They are a wonderful group of skilled, caring, and attentive
          people.”</p>
      </div>
    </testimonial>


    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'Avigora'}" class="left">
              <img src="@/assets/casestudy/btn-avigora.jpg" alt="Avigora">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Fyber'}" class="right">
              <img src="@/assets/casestudy/btn-fyber.jpg" alt="Fyber">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#789c48"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Summerwood",
  components: {LetsChat, Testimonial, ContactUs, Footer}
}
</script>

<style lang="scss">
.logo-sw img {
  max-width: 320px;
}

.sw-laptop {
  background-color: #789c48;
}

.sw-about {
  background-color: #F3F3F3;
}

.container-about h3, .container-about p {
  color: #56595c;
}

.sw-p {
  background: #789c48;
}

.sw-s {
  background: #4b3d2b;
}

.sw-t {
  background: #89725b;
}

.sw-bg a, .testimonial-sw {
  background: #4b3d2b;
}

.primary-sw {
  color: #789c48;
}

.secondary-sw {
  color: #4b3d2b;
}

.third-sw {
  color: #89725b;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #789c48 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>